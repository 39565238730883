<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

        <div class="d-none" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Carpeta id:</mat-label>
                <input name="nombre" autocomplete="off" matInput formControlName="carpeta_id"  [(ngModel)]="carpeta_id">
            </mat-form-field>
        </div>



            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre recuro:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
                </mat-form-field>
            </div>



            <div class="row" style="text-align: center; justify-content: center; ">
                <!-- <div class="col-12">
                    <img [src]="imgSelect" alt="" width="150px" > 
                </div> -->
                <div class="col-12" style="padding-top: 10px;">
                    <!-- <input type="file" class="cz-file-drop-input" accept="application/pdf" (change)="imgSelected($event)"> -->
                    <input type="file" class="cz-file-drop-input" accept="image/jpeg, image/png, image/gif" (change)="imgSelected($event)">
                </div>
            </div>
            
            
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>