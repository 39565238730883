<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">




       

            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombres:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="nombre" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Apellidos:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="apellidos" >
                    </mat-form-field>
                </div>
            </div>


            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Correo:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="email" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Telefono:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="celular" >
                    </mat-form-field>
                </div>
            </div>



            <div class="row" >
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>DNI:</mat-label>
                        <input name="ruc" autocomplete="off" matInput formControlName="dni" >
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Contraseña:</mat-label>
                        <input name="razon" autocomplete="off" matInput formControlName="password_show" >
                    </mat-form-field>
                </div>
            </div>



        
          

            <div class="class d-none" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Area</mat-label>
                    <mat-select  formControlName="area_id">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of areas" [value]="wType._id">
                            {{wType.nota}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>