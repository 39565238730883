<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Mis imagenes de entrenamiento</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-12 text-right">
                               
                            </div>

                            <div class="col-12 text-right">
                                <button  class="btn btn-success" routerLink="/admin/carpetas.list"><i class="fas fa-arrow-left mr-2"> </i>IR ATRAS</button>&nbsp;
                                <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1" > </i> NUEVO</button>&nbsp;
                            </div>
                        </div>
                </div>



                <div class="row m-3 text-center" >

                    <div class="col-4" *ngFor="let data of recursos">
                        <br>
                        <i class="fas fa-file-image" style="padding-right: 5px; color: #1BC5BD; font-size: 80px; cursor: pointer;"></i>
                        <p style="padding-top: 10px;">Nombre: {{data.nombre}}</p>
                        <p>Enlace: <a target="_blank" [href]='IMG+data.imagen'>{{data.imagen}}</a></p>
                        <p>Creado: {{data.created | date: 'dd/MM/yyyy'}}</p>
                        <button  class="cursor btn btn-dark  mr-2" style="font-size: 15px;" title="Eliminar actividad" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                    </div>

                   
                </div>

                



            </div>

        </div>
    </div>
</div>