<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">

                <div class="card-body p-4 justify-content-between">
                    <div class="row">
                        <div class="col-6">
                            <h4>Bienvenido, {{ user }}</h4>
                        </div>
                        <div class="col-6 text-right">
                            <h4 class="">{{ today | today }}</h4>
                        </div>
                    </div>

                </div>
                <div class="card-footer bg-white">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>

     




    <!-- <div class="row" >
        <div  class="col-3  mt-3 cursor-pointer" >
            <div class="card" routerLink="repositorios.list" style="background-image: linear-gradient( #db96cc, #c204fc) ;">
                <div class="center-card">
                    <img src="assets/menu/nuevo-documento.png" width="100px">
                </div>
                <button class="btn btn-primary-outline btn-outline"><p style="color: #fff;">REPOSITORIOS</p> </button>&nbsp;
            </div>
        </div>

        <div  class="col-3  mt-3 cursor-pointer" >
            <div class="card" routerLink="herramienta.list" style="background-image: linear-gradient( #94d3b1, #05c0a1) ;">
                <div class="center-card">
                    <img src="assets/menu/trabajo-en-progreso.png" width="100px">
                </div>
                <button class="btn btn-primary-outline"><p style="color: #fff;">HERRAMIENTAS</p></button>&nbsp;
            </div>
        </div>

        <div class="col-3  mt-3 cursor-pointer">
            <div class="card" routerLink="capacitaciones.list" style="background-image: linear-gradient( #c5c5a0, #fcce04) ;">
                <div class="center-card">
                    <img src="assets/menu/terminar.png" width="100px">
                </div>
                <button class="btn btn-primary-outline"><p style="color: #fff;">CAPACITACIONES / CURSO</p></button>&nbsp;
            </div>
        </div>


        <div  class="col-3  mt-3 cursor-pointer" routerLink="cliente.list">
            <div class="card" style="background-image: linear-gradient( #e0ab93, #fc8804) ;">
                <div class="center-card">
                    <img src="assets/menu/empleados.png" width="100px">
                </div>
                <button class="btn btn-primary-outline"><p style="color: #fff;">USUARIOS APP</p></button>&nbsp;
            </div>
        </div>



        <div  class="col-3  mt-3 cursor-pointer">
            <div class="card" routerLink="security.users" style="background-image: linear-gradient( #59A9F3, #045BFC) ;" >
                <div class="center-card" >
                    <img src="assets/menu/users.png" width="100px">
                </div>
                <button class="btn btn-primary-outline"><p style="color: #fff;">ADMINISTRADORES</p></button>&nbsp;
            </div>
        </div>

        <div  class="col-3  mt-3 cursor-pointer" >
            <div class="card" style="background-image: linear-gradient( #7e8081, #242729) ;">
                <div class="center-card">
                    <img src="assets/menu/estadisticas.png" width="100px">
                </div>
                <button class="btn btn-primary-outline"><p style="color: #fff;">REPORTES</p></button>&nbsp;
            </div>
        </div>
    </div> -->



</div>